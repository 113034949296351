<template>
  <div class="relative">
    <UiOnClickOutside :key="periodId" :do="closeDropdown">
      <div>
        <div
          v-if="lesson"
          class="lesson px-5 py-4 rounded-sm relative"
          :class="bgColor"
          :draggable="true"
          @dragstart="startDrag"
        >
          <div
            class="cursor-pointer h-6 w-6 rounded-full absolute flex justify-center items-center ltr:right-4 top-3 rtl:left-2"
            :class="isOpen ? 'bg-primary-purple-50 ' : 'bg-primary-gray-50'"
            @click="toggleDropdown"
          >
            <icon
              class="icon w-2.5 h-2.5"
              icon="editFeatherLine"
              :color="isOpen ? 'primary-purple-700' : 'primary-gray-400'"
            />
          </div>

          <div
            :title="lesson.title"
            class="text-sm font-inter text-label-text font-medium leading-4 w-full truncate"
          >
            {{ lesson.lesson_number }} - {{ lesson.title }}
          </div>
          <div class="flex lg:gap-x-5 gap-x-1 mt-5 justify-between flex-wrap">
            <div class="meta-info font-inter text-menu leading-4">
              {{ lesson.topic.chapter.subject.title }}
            </div>
            <div class="meta-info font-inter text-menu leading-4">
              Chapter {{ lesson.chapter_number }}
            </div>
            <div
              :title="lesson.type"
              class="meta-info font-inter text-menu leading-4 w-1/4 truncate"
            >
              {{ lesson.type }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="cursor-pointer flex items-center justify-center gap-3 lesson-placeholder border border-dashed rounded-md p-7 sm:text-sm text-xs font-medium leading-4 text-center text-primary-purple-600 bg-bg-color-addlesson"
          @click="toggleDropdown"
        >
          <div
            class="border-dashed border border-primary-purple-600 p-1.5 items-center rounded-full"
          >
            <icon class="icon w-2 h-2" icon="plus" color="primary-purple-600" />
          </div>
          Add lesson
        </div>
        <UiMenuDropDown
          :class="$style.dropDownPosition"
          :is-open="isOpen"
          class="rounded-lg shadow-lg"
        >
          <div
            class="flex items-center w-full h-auto md:py-3 pt-1 pb-2 border-b border-border-line"
          >
            <span class="absolute ltr:left-5 rtl:right-5">
              <icon class="icon" icon="search" color="menu" height="12" width="12" />
            </span>
            <input
              v-model="query"
              type="text"
              name="search"
              placeholder="Search"
              class="h-10 bg-primary-gray-50 rounded text-sm md:text-base focus:outline-none text-primary-gray-500 font-inter px-8 w-full"
              @keyup="searchLesson"
              @focus="isActive = true"
              @blur="isActive = false"
            />
          </div>
          <ul>
            <ul
              v-if="lesson"
              class="font-inter py-2.5 w-auto font-normal text-sm md:text-base text-gray-500 max-h-60 overflow-auto border-b border-border-line"
            >
              <li
                class="flex ltr:flex-row rtl:flex-row-reverse pr-2 items-center justify-between h-15 px-1.5 rounded hover:text-gray-500 bg-primary-gray-50 hover:font-bold"
              >
                <div class="flex flex-col justify-between w-5/6">
                  <div class="flex flex-row justify-between my-1">
                    <div class="flex justify-between w-full">
                      <span class="text-primary-gray-500">
                        {{ lesson.lesson_number }} - {{ lesson.title }}
                      </span>
                      <span
                        class="px-1 rounded text-sm text-primary-gray-500 leading-6 bg-bg-color-light"
                      >
                        {{ lesson.period_count }}
                      </span>
                    </div>
                  </div>
                  <div class="flex lg:gap-x-5 gap-x-1 my-1 justify-between">
                    <span class="meta-info font-inter text-menu leading-3">
                      Chapter {{ lesson.chapter_number }}
                    </span>
                    <span class="meta-info font-inter text-menu leading-3">
                      {{ lesson.type }}
                    </span>
                  </div>
                </div>
                <div
                  class="mx-auto self-baseline mt-2 cursor-pointer"
                  @click="changeSelectedLesson(lesson, 'unassign')"
                >
                  <icon
                    class="icon"
                    color="primary-purple-700"
                    icon="crossCircle"
                    height="16"
                    width="16"
                  />
                </div>
              </li>
            </ul>
            <div v-if="lessons.length" class="pt-1 h-40 overflow-y-auto overflow-x-hidden">
              <li
                v-for="record in lessons"
                :key="record.id"
                class="cursor-pointer font-inter rounded p-1 hover:bg-bg-color-light text-sm md:text-base items-center mr-2"
                @click="changeSelectedLesson(record, 'assign', record.periods)"
              >
                <div v-if="!lesson || lesson.title !== record.title" class="flex flex-col">
                  <div class="flex justify-between">
                    <span class="text-menu">{{ record.title }}</span>
                    <span class="px-1 rounded text-menu text-sm leading-6 bg-bg-color-light">
                      {{ record.period_count }}
                    </span>
                  </div>
                  <div class="flex lg:gap-x-5 gap-x-1 mt-2.5 justify-between">
                    <span class="meta-info text-xs font-inter text-menu leading-4">
                      {{ record.topic.chapter.subject.title }}
                    </span>
                    <span class="meta-info text-xs font-inter text-menu leading-4">
                      Chapter {{ record.chapter_number }}
                    </span>
                    <span class="meta-info font-inter text-menu leading-4">
                      {{ record.type }}
                    </span>
                  </div>
                </div>
              </li>
            </div>
            <div v-else class="flex justify-center items-center">
              <p class="text-base py-2 text-center text-action-bg">
                <span>
                  <NoRecordFound />
                </span>
              </p>
            </div>
          </ul>
        </UiMenuDropDown>
      </div>
    </UiOnClickOutside>
  </div>
</template>

<script>
import UiOnClickOutside from '@src/components/UiElements/UiOnClickOutside.vue'
import UiMenuDropDown from '@src/components/UiElements/UiMenuDropDown.vue'
import icon from '@src/components/icons/icon.vue'
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
export default {
  name: 'LessonPlaceholder',
  components: {
    UiMenuDropDown,
    UiOnClickOutside,
    icon,
    NoRecordFound,
  },
  props: {
    periodId: {
      type: String,
      default: '',
    },
    lesson: {
      type: Object,
      default: () => {},
    },
    selectedSubject: {
      type: String,
      default: '',
    },
    bgColor: { type: String, required: true },
  },
  emits: ['lessonCounts', 'selectedLesson'],
  data() {
    return {
      color: 'white',
      isOpen: false,
      isActive: false,
      query: '',
      selectedLesson: null,
      lessons: [],
    }
  },
  mounted() {
    this.filterLessonList()
  },
  methods: {
    filterLessonList(title = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', title),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.selectedSubject),
        },
      }
      this.getLessons(payload).then((response) => {
        this.lessons = response.records
        this.$emit('lessonCounts', response.meta.total_records)
      })
    },
    startDrag(evt, id) {
      const data = JSON.stringify({
        periodId: this.periodId,
        lesson: this.lesson,
      })
      evt.dataTransfer.setData('dragInfo', data)
    },
    searchLesson: debounce(function () {
      this.filterLessonList(this.query)
    }, 300),
    toggleDropdown() {
      this.isOpen = !this.isOpen
    },
    closeDropdown() {
      if (!this.isActive && this.isOpen) {
        this.isOpen = false
        this.query = ''
      }
    },
    changeSelectedLesson(selectedLesson, type) {
      this.toggleDropdown()
      this.$emit('selectedLesson', selectedLesson, this.periodId, type)
      this.query = ''
    },
    ...mapActions('lesson', ['getLessons']),
  },
}
</script>

<style lang="scss" module>
.dropDownPosition {
  top: 84px;
}
</style>
