<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="LES_PL"
      tooltip-title="LES_PL"
      :display-breadcrumb="true"
    />
    <section class="minHieght100 relative mt-5 rounded-t-lg lg:mx-0 mx-5">
      <div class="planner-header flex justify-between items-center">
        <div class="flex gap-2 flex-row justify-between items-center w-full">
          <div class="flex gap-2.5 whitespace-nowrap w-full justify-end">
            <CalendarActionsWrapper
              :calendar-date="currentDate"
              filter-view="Day"
              @selectDate="fetchPeriodsData"
            />
            <UiSingleSelect
              v-model="selectedSubject"
              :hide-title="true"
              :options="subjectList"
              label="title"
              placeholder="Select Subject"
              reduce="id"
              class="sm:w-fit"
              :no-error="true"
              :search-through-api="true"
              @search="getSubjectList"
              @change="fetchFilteredData(selectedSubject)"
              @deSelect="fetchFilteredData(selectedSubject)"
            />
          </div>
        </div>
      </div>
      <template v-if="isLoading"><Loader class="my-40" :content="true" /></template>

      <div v-else class="planner-body mt-5 border rounded-xl overflow-auto mb-16 bg-white">
        <div class="planner-title flex">
          <div class="min-w-3.5 w-2/3 flex p-5">
            <div class="flex-1 font-roboto text-base text-label-text font-semibold">Period at</div>
            <div
              v-i18n="dashboard"
              class="flex-1 font-roboto text-base text-label-text font-semibold"
            >
              Lesson (Assigned)
            </div>
          </div>
          <div
            v-i18n="dashboard"
            class="min-w-2 w-1/3 p-5 font-roboto text-base text-label-text font-medium border-l"
          >
            Lesson (Unassigned)
          </div>
        </div>
        <div
          class="sm:minHieght80 minHieght50 planner-content flex border-t"
          :class="{ overlay: apiLoading }"
        >
          <div class="relative min-w-3.5 w-2/3 p-2.5" @dragover.stop>
            <Loader v-if="isPeriodSectionLoading" class="-mt-11" :content="true" />
            <div v-else-if="!isPeriodSectionLoading && isEmpty(periodsList)">
              <NoRecordFound />
            </div>
            <div v-else class="flex flex-col gap-y-2.5">
              <SchedulledLesson
                v-for="(period, index) in periodsList"
                :key="index"
                :period="period"
                @onLessonDropped="lessonDropped($event, period.id)"
              >
                <LessonPlaceholder
                  :key="index"
                  :period-id="period.id"
                  :lesson="period.lesson"
                  bg-color="bg-white"
                  :selected-subject="selectedSubject"
                  @selectedLesson="lessonAssignment"
                  @lessonCounts="lessonCountHandler"
                />
              </SchedulledLesson>
            </div>
            <Pagination
              v-if="isPeriodsPaginationDisplay"
              v-show="!isPeriodSectionLoading"
              :record-limit="GENERAL_CONSTANT.MODAL_RECORD_LIMIT"
              :max-range="paginationCounts(periodsCounts, GENERAL_CONSTANT.MODAL_RECORD_LIMIT)"
              @filterRecord="fetchPaginatedPeriods"
            />
          </div>
          <div
            class="min-w-2 w-1/3 p-2.5 border-l flex justify-between flex-col"
            @dragover.stop
            @dragover.prevent
            @drop.prevent="onLessonDrop"
          >
            <Loader v-if="isLessonLoading" class="-mt-11" :content="true" />
            <div v-else-if="!isLessonLoading && isEmpty(unAssignedLessonsList)">
              <NoRecordFound />
            </div>
            <div v-else class="flex flex-col sm:gap-y-7.5 gap-y-7">
              <Lesson
                v-for="(lesson, key) in unAssignedLessonsList"
                :key="forceRerender + key"
                :lesson="lesson"
                bg-color="bg-bg-color-lighter"
                :class="{ 'mt-2.5': key === 0 }"
              />
            </div>
            <Pagination
              v-if="isUnassignedLessonPaginationDisplay"
              v-show="!isLessonLoading"
              :record-limit="GENERAL_CONSTANT.MODAL_RECORD_LIMIT"
              :max-range="
                paginationCounts(unassignedLessonCounts, GENERAL_CONSTANT.MODAL_RECORD_LIMIT)
              "
              @filterRecord="fetchUnassignedLesson"
            />
          </div>
        </div>
      </div>
    </section>
    <LessonModal v-if="showModal" :modal="showModal" @toggle="toggleModal" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import generalUtil from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import LessonModal from '@src/router/views/lesson-planner/lesson/lesson-modal.vue'
import Lesson from './Lesson.vue'
import LessonPlaceholder from './LessonPlaceholder.vue'
import SchedulledLesson from './SchedulledLesson.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import {
  formatDateFromLocalToUTC,
  getStartTimeOfTheDay,
  getEndTimeOfTheDay,
} from '@utils/moment.util'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
import GENERAL_CONSTANT from '@src/constants/general-constants.js'

export default {
  name: 'SubjectLessonPlanner',
  components: {
    Loader,
    UiSingleSelect,
    TitleWrapper,
    Lesson,
    LessonModal,
    LessonPlaceholder,
    SchedulledLesson,
    NoRecordFound,
    CalendarActionsWrapper,
    Pagination,
  },
  mixins: [generalUtil],
  data() {
    return {
      GENERAL_CONSTANT,
      attendanceTranslation: 'attendanceTranslation',
      isEmpty,
      title: 'title',
      periodsList: [],
      subjectList: [],
      unAssignedLessonsList: [],
      selectedSubject: '',
      isLoading: false,
      dashboard: 'dashboard',
      apiLoading: false,
      forceRerender: 0,
      skipCount: null,
      showPagination: false,
      pageNumber: 1,
      periodsCounts: 0,
      unassignedLessonCounts: 0,
      direction: null,
      currentDate: '',
      isLessonLoading: false,
      isPeriodSectionLoading: false,
      lessonPaginationPayload: null,
      periodsPaginationPayload: null,
      totalLessonCount: 0,
    }
  },
  page: {
    title: 'Lesson Planner',
    meta: [
      {
        name: 'description',
        content: 'Lesson Planner ',
      },
    ],
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      showModal: (state) => state.layout.showModal,
    }),
    ...mapGetters('layout', ['campusTimeZone']),
    isPeriodsPaginationDisplay() {
      return this.periodsCounts > GENERAL_CONSTANT.MODAL_RECORD_LIMIT
    },
    isUnassignedLessonPaginationDisplay() {
      return this.unassignedLessonCounts > GENERAL_CONSTANT.MODAL_RECORD_LIMIT
    },
  },
  watch: {
    currentSectionScope: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) this.initilizeData()
      },
    },
  },
  mounted() {
    this.filterLessonList()
    this.setRightBarData()
  },
  methods: {
    paginationCounts,
    initilizeData() {
      this.lessonPaginationPayload = paginationRangeHandler(
        null,
        GENERAL_CONSTANT.MODAL_RECORD_LIMIT,
      )
      this.currentDate = new Date()
      this.isLoading = true
      let UTCDate = formatDateFromLocalToUTC(this.currentDate)
      let filterData = this.getFilterData(UTCDate)
      Promise.all([
        this.getPaginatedPeriod(filterData),
        this.getUnassignedLessons(this.lessonPaginationPayload),
        this.classSubjects(),
      ])
        .then((response) => {
          let [periodsData, unassignedLessonsData, subjectsData] = response
          this.periodsList = periodsData?.data?.records
          this.periodsCounts = periodsData.data?.meta?.total_records
          this.unAssignedLessonsList = unassignedLessonsData.records
          this.unassignedLessonCounts = unassignedLessonsData.meta.total_records
          this.subjectList = subjectsData.data.records
          this.setRightBarData()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSubjectList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((resp) => {
        this.subjectList = resp?.data?.records || []
      })
    },
    fetchPaginatedPeriods(range) {
      this.isPeriodSectionLoading = true
      let UTCDate = formatDateFromLocalToUTC(this.currentDate)
      let filterData = this.getFilterData(UTCDate, this.selectedSubject, range)
      this.getPaginatedPeriod(filterData).then((response) => {
        this.periodsList = response?.data?.records
        this.periodsCounts = response?.data?.meta?.total_records
        this.isPeriodSectionLoading = false
        this.setRightBarData()
      })
    },

    fetchPeriodsData(date, subjectId = this.selectedSubject, range = null) {
      this.isLoading = true
      this.currentDate = date
      let UTCDate = formatDateFromLocalToUTC(this.currentDate)
      let filterData = this.getFilterData(UTCDate, subjectId, range)
      this.getPaginatedPeriod(filterData)
        .then((response) => {
          this.fetchUnassignedLesson()
          this.periodsList = response?.data?.records
          this.periodsCounts = response?.data?.meta?.total_records
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    lessonCountHandler(count = 0) {
      this.totalLessonCount = count
      this.setRightBarData()
    },
    setRightBarData() {
      this.setRightbarContent({
        header: {
          title: 'Lessons',
          buttons: [
            {
              title: 'Add New Lesson',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
          ],
        },
        stats: [
          {
            title: 'Periods',
            categories: [{ text: 'TOTAL_PERIOD', value: this.periodsCounts }],
          },
          {
            title: 'Unassigned Lesson',
            categories: [{ text: 'TOTAL_U_LESSON', value: this.unassignedLessonCounts }],
          },
          {
            title: 'Lessons',
            categories: [{ text: 'TOTAL_LESSONS', value: this.totalLessonCount }],
          },
        ],
      })
    },
    fetchUnassignedLesson(range = null, subjectId = this.selectedSubject) {
      this.isLessonLoading = true
      this.lessonPaginationPayload = paginationRangeHandler(
        range,
        GENERAL_CONSTANT.MODAL_RECORD_LIMIT,
      )
      let payload = {
        ...this.lessonPaginationPayload,
        $where: { ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', subjectId) },
      }
      this.getUnassignedLessons(payload).then((response) => {
        this.unAssignedLessonsList = response.records
        this.unassignedLessonCounts = response.meta.total_records
        this.isLessonLoading = false
        this.setRightBarData()
      })
    },
    fetchFilteredData(id) {
      Promise.all([
        this.fetchPeriodsData(this.currentDate, id),
        this.fetchUnassignedLesson(null, id),
        this.filterLessonList(),
      ])
    },
    filterLessonList() {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.selectedSubject),
        },
      }
      this.getLessons(payload).then((response) => {
        this.lessonCountHandler(response.meta.total_records)
      })
    },

    getFilterData(UTCDate, subjectId, range) {
      this.periodsPaginationPayload = paginationRangeHandler(
        range,
        GENERAL_CONSTANT.MODAL_RECORD_LIMIT,
      )
      let queries = { ...this.periodsPaginationPayload, $where: {} }
      let startDay = buildWhereQuery(
        FILTER_KEYS.GREATER_THAN_EQUAL,
        'started_at',
        `${getStartTimeOfTheDay(UTCDate)}${this.campusTimeZone}`,
      )
      let endDay = buildWhereQuery(
        FILTER_KEYS.LESS_THAN_EQUAL,
        'started_at',
        `${getEndTimeOfTheDay(UTCDate)}${this.campusTimeZone}`,
      )
      queries.$where.started_at = { ...Object.values(startDay)[0], ...Object.values(endDay)[0] }
      queries.$where = {
        ...queries.$where,
        ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', subjectId),
      }
      return queries
    },
    /**
     * Drag lesson and drop to schedule to assign it
     * @param {Object} dropInfo which we want from unassign
     * @param {String} scheduleId whome from we want to unassign
     * @return {Array} Schedules
     */
    lessonDropped(dropInfo, periodId) {
      const dropDataInfo = JSON.parse(dropInfo)
      const droppedPeriod = this.periodsList.find(
        (period) =>
          period.id === periodId && period.lesson && period.lesson.id === dropDataInfo.lessonId,
      )
      if (!droppedPeriod) {
        this.assignLessonToPeriodHandler(dropDataInfo.lesson, periodId)
      }
    },
    lessonAssignment(selectedLesson, period, type) {
      if (type === 'assign') {
        this.assignLessonToPeriodHandler(selectedLesson, period)
      } else if (type === 'unassign') {
        this.unAssignLessonToPeriodHandler(selectedLesson, period)
      }
    },

    /**
     * Assign lecture to some schedule
     * @param {Object} selectedLesson which we want to assign
     * @param {Object} schedule whome to assign
     * @return {Array} Schedules
     */

    assignLessonToPeriodHandler(selectedLesson, periodId) {
      this.apiLoading = true
      this.assignLessonToPeriod({
        period_id: periodId,
        lesson_id: selectedLesson.id,
      })
        .then(() => {
          this.fetchFilteredData(this.selectedSubject)
        })
        .finally(() => {
          this.forceRerender++
          this.apiLoading = false
        })
    },

    /**
     * Unassign lecture to some schedule
     * @param {Object} selectedLesson which we want from unassign
     * @param {Object} schedule whome from we want to unassign
     * @return {Array} Schedules
     */
    unAssignLessonToPeriodHandler(selectedLesson, periodId) {
      this.apiLoading = true
      this.unAssignLessonToPeriod({
        period_id: periodId,
      })
        .then((res) => {
          this.fetchFilteredData(this.selectedSubject)
        })
        .finally(() => {
          this.apiLoading = false
        })
    },

    /**
     * Unassign if some schedule already assign
     * @param {Object} dropInfo Schedule and lesson details
     */
    onLessonDrop(evt) {
      const dragInfo = evt.dataTransfer.getData('dragInfo')
      const dropDataInfo = JSON.parse(dragInfo)
      if (dropDataInfo.periodId) {
        this.unAssignLessonToPeriodHandler(dropDataInfo.lesson, dropDataInfo.periodId)
      }
    },
    toggleModal() {
      this.setShowModal()
      this.fetchUnassignedLesson()
    },
    ...mapActions('period', ['getPaginatedPeriod']),
    ...mapActions('lesson', [
      'getUnassignedLessons',
      'assignLessonToPeriod',
      'unAssignLessonToPeriod',
      'getLessons',
    ]),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>

<style lang="scss" scopped>
.overlay {
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: progress;
    content: '';
    background: #ffffff91;
  }
}
.calender-action section {
  margin-left: 0;
}
</style>
