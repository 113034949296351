<template>
  <div
    class="lesson px-4 py-4 rounded-md relative"
    :class="bgColor"
    :draggable="true"
    @dragstart="startDrag"
  >
    <div class="flex justify-between items-center">
      <div
        :title="lesson.title"
        class="text-sm font-roboto text-label-text font-medium leading-4 w-3/5 truncate"
      >
        {{ lesson.lesson_number }} - {{ lesson.title }}
      </div>
      <div
        :title="lesson.type"
        class="text-sm font-roboto text-label-text font-medium leading-4 w-2/5 truncate text-right"
      >
        {{ lesson.type }}
      </div>
    </div>
    <div class="flex gap-x-4 mt-5 justify-between items-end">
      <div :title="subjectTitle" class="meta-info font-roboto text-label-text leading-4 truncate">
        {{ subjectTitle }}
      </div>
      <div
        :title="chapterTitle"
        class="meta-info font-roboto text-label-text leading-4 w-1/4 truncate text-center"
      >
        Chapter {{ chapterNumber }}
      </div>
      <div
        :title="topicTitle"
        class="meta-info font-roboto text-label-text leading-4 w-1/4 truncate text-right"
      >
        {{ topicTitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lesson',
  props: {
    lesson: { type: Object, required: true },
    bgColor: { type: String, required: true },
    scheduled: { type: String, default: '' },
  },
  computed: {
    subjectTitle() {
      return this.lesson?.topic?.chapter?.subject?.title
    },
    chapterTitle() {
      return this.lesson?.topic?.chapter?.title
    },
    chapterNumber() {
      return this.lesson?.topic?.chapter?.number
    },
    topicTitle() {
      return this.lesson?.topic?.title
    },
  },
  methods: {
    startDrag(evt, id) {
      const data = JSON.stringify({
        scheduled: this.scheduled,
        lesson: this.lesson,
      })
      evt.dataTransfer.setData('dragInfo', data)
    },
  },
}
</script>

<style lang="scss">
.lesson-padding-y {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.meta-info {
  font-size: 0.6875rem;
}
@media (max-width: 768px) {
  .meta-info {
    font-size: 0.61rem;
  }
}
</style>
