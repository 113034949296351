<template>
  <div class="p-2.5 flex bg-bg-color-lighter rounded-md" @dragover.prevent @drop.prevent="onDrop">
    <div class="w-1/2 py-1 px-5">
      <div class="text-sm text-label-text font-medium leading-4">{{ period.subject.title }}</div>
      <div class="text-sm text-label-text font-medium leading-4 mt-2.5">
        {{ $filters.formattedDate(period.started_at, DATE_TIME_CONSTANT.DATE) }}
      </div>
      <div class="meta-info mt-2">
        <span>
          {{ $filters.formattedDate(period.started_at, DATE_TIME_CONSTANT.TIME12H) }}
        </span>
        -
        <span>
          {{ $filters.formattedDate(period.ended_at, DATE_TIME_CONSTANT.TIME12H) }}
        </span>
      </div>
    </div>
    <div class="w-1/2">
      <slot />
    </div>
  </div>
</template>

<script>
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'

export default {
  name: 'SchedulledLesson',
  props: {
    period: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['onLessonDropped'],

  data() {
    return {
      DATE_TIME_CONSTANT,
    }
  },
  methods: {
    onDrop(evt) {
      const dragInfo = evt.dataTransfer.getData('dragInfo')
      this.$emit('onLessonDropped', dragInfo)
    },
  },
}
</script>

<style lang="scss" scoped>
.lesson-padding-y {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.meta-info {
  font-size: 0.6875rem;
}
</style>
